import React, { useState } from "react";
import { isMobile } from "react-device-detect";
import { Link } from "@material-ui/core";
import { HeaderCell, IdCell, TranslatedCell } from "./Cells";
import { OnlineColumnFilter, AutocompleteColumnFilter, OnlineColumnFilter2, AccessGroupsColumnFilter, AggregatedParentsColumnFilter } from "./Filters";
import { GridColumn } from "src/components/DataGrid";
import { DeviceDto } from "../../../services/DeviceDataService";
import { useTranslator } from "../TranslationProvider";
import { AccessGroup } from "src/services/ConfigurationsService";
import { FilterProps } from "react-table";

const linkClickHandler = (ev: React.MouseEvent<HTMLAnchorElement>) => {
    ev.stopPropagation();
};

export const equalsSome = (rows:any, ids:any, filterValue:Array<any>) => {
    return filterValue ? rows.filter((row:any) => {
      return ids.some((id:any) => {
        const rowValue = row.values[id]
        return (
          rowValue &&
          
                  // eslint-disable-next-line eqeqeq
          filterValue?.some((val:any) => rowValue==(val))
        )
      })
    }):[]
}

export const aggregatedParentsFilter = (rows:any, ids:any, filterValue:Array<any>) => {
    return filterValue ? rows.filter((row:any) => {
      return ids.some((id:any) => {
        const rowValue = row.values[id] ? JSON.parse(row.values[id]) : []
        return (
          filterValue?.some((val:any) => rowValue.includes(val))
        )
      })
    }):[]
}

export const accessGroupsFilter = (rows:any, ids:any, filterValue:Array<any>) => {
return filterValue ? rows.filter((row:any) => {
    return filterValue.filter((val:Array<number>) => val.length !== 0).some((val:Array<number>) => {
        return val.every((group:number) => {
            return (row.values[ids[0]] ? JSON.parse(row.values[ids[0]]) : []).includes(group);
        })
    })
}):[]
}

export const accessGroupsFilterChip = ({ filterValue, column: { id } }: any, accessGroups: AccessGroup[] | undefined) => {
    
    return (
        <>
            {id}:{" "}
            {filterValue.map((val:Array<number>) => {
                return "("+val.map((group:number) => {
                    return accessGroups?.find((ag) => ag.Id === group)?.Name;
                }).join(", ")+")";
            }).join(" or ")}
        </>
    );
}
 
function getColumnProps(key: string): Partial<GridColumn<DeviceDto>> {
    switch (key) {
        case "Dealer":
        case "customer_id": {
            return {
                disableFilters: false,
                Filter: AutocompleteColumnFilter,
                filterOrder: key === "Dealer" ? 4 : 5,
                filter: equalsSome,
                filterShouldRenderCell: true,
                FilterChip: ({ filterValue, column: { id } }) => {
                    const translator = useTranslator();
                    return (
                        <>
                            {translator.translateKey(id!)}:{" "}
                            {translator.translate(id!, filterValue)}
                        </>
                    );
                },
            };
        }
        case "Aggregated Parents": {
            return {
                disableFilters: false,
                Filter: AggregatedParentsColumnFilter,
                filterOrder: 6,
                filter: aggregatedParentsFilter,
                filterShouldRenderCell: true,
                FilterChip: ({ filterValue, column: { id } }) => {
                    const translator = useTranslator();
                    return (
                        <>
                            {translator.translateKey(id!)}:{" "}
                            {translator.translate("customer_id"!, filterValue)}
                        </>
                    );
                },
            };
        }
        case "Server online": {
            return {
                disableFilters: false,
                Filter: OnlineColumnFilter,
                filterOrder: 3,
                filter: "multiColumn",
                placeFilterOnToolbar: true,
                FilterChip: () => "Hide offline devices",
                
            };
        }
        case "connected": {
            return {
                disableFilters: false,
                Filter: OnlineColumnFilter2,
                filterOrder: 3,
                filter: "multiColumn",
                placeFilterOnToolbar: true,
                FilterChip: () => "Hide offline devices",
            };
        }
        case "Customer Access Groups":
        case "Robot Access Groups": {
            return {
                disableFilters: false,
                filter: accessGroupsFilter,
                placeFilterOnToolbar: true,
                //FilterChip: () => key
                
            };
        }
    }
    return {
        disableFilters: false,
        filter: equalsSome,
        Filter: AutocompleteColumnFilter,
    };
}

function makeColumns(_deviceType: number, deviceName: string, keys: string[], accessGroups: AccessGroup[] | undefined) {
    function AccessGroupsF(filterProps: FilterProps<DeviceDto>)
    {
        return AccessGroupsColumnFilter(filterProps, accessGroups)
    }
    function AccessGroupsFilterChipF({ filterValue, column: { id } }: any){
        return accessGroupsFilterChip({ filterValue, column: { id } }, accessGroups)
    }
    return {
        fixed: {
            Header: HeaderCell,
            id: "deviceId",
            accessor: "deviceId",
            width: isMobile ? 110 : 120,
            name: `${deviceName} ID`,
            Cell: IdCell,
            ...(getColumnProps("deviceId") as any),
        } as GridColumn<DeviceDto> & { width: number },
        keys: keys.map(
            (key) =>
                ((key === "Customer Access Groups" || key === "Robot Access Groups") && {
                Header: HeaderCell,
                accessor: `data.${key}`,
                Cell: TranslatedCell,
                id: key,
                name: key,
                ...getColumnProps(key),
                Filter: AccessGroupsF,
                FilterChip: AccessGroupsFilterChipF,
                }) ||
                (key !== "Location Link" && {
                    Header: HeaderCell,
                    accessor: `data.${key}`,
                    Cell: TranslatedCell,
                    id: key,
                    name: key,
                    ...getColumnProps(key),
                }) || {
                    id: key,
                    name: key,
                    Header: HeaderCell,
                    accessor: (orig) => {
                        const datumYX = orig.data["Location Link"]?.split(" ");

                        const datumY = datumYX?.[0];
                        const datumX = datumYX?.[1];

                        if (datumY && datumX) {
                            return (
                                "https://www.google.com/maps/search/?api=1&query=" +
                                datumY +
                                "," +
                                datumX
                            );
                        }

                        return "";
                    },
                    Cell: ({ value }) => {
                        if (value === "") {
                            return <span>No coordinates found</span>;
                        }

                        return (
                            <Link
                                href={value}
                                target="_blank"
                                onClick={linkClickHandler}
                            >
                                Click Me
                            </Link>
                        );
                    },
                }
        ) as GridColumn<DeviceDto>[],
    };
}

export interface ColumnOptions {
    deviceType: number;
    deviceName: string;
    keys: string[];
    accessGroups: AccessGroup[] | undefined;
}

export default function useColumns(options: ColumnOptions) {
    const { deviceType, deviceName, keys, accessGroups } = options;
    const [cols] = useState(() => makeColumns(deviceType, deviceName, keys, accessGroups));
    return cols;
}
