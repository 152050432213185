import React, { useCallback, useEffect, useMemo } from "react";
import TextField from "@material-ui/core/TextField";
import BaseWidget, { defaultRules, WidgetProps } from "./BaseWidget";
import getErrorProps from "src/utils/getErrorProps";
import { useForm, Controller } from "react-hook-form";
import { useSelector } from "react-redux";
import { useWidgetState } from "../plugins";
import { mapInheritableAccessGroups2 } from "../mapInheritableAccessGroups";
import ConfigurationsService from "src/services/ConfigurationsService";
import { useAsync, useErrorHandler, useToast, useGetLatest } from "src/hooks";
import {  customersArraySelector, userSelector } from "src/redux/app/selectors";
import type { Customer, CustomerAccessGroup } from "src/services/UserService";
import AutocompleteV2 from "src/components/AutocompleteV2";

interface UpdateCustomerInput {
    customer: Customer | null;
    accessGroups: CustomerAccessGroup[][] | null;
}

export default function UpdateCustomerWidget({ widgetName,saveChanges, favouriteWidgets, setFavouriteWidgets  }: WidgetProps) {
    const {
        handleSubmit,
        errors,
        setError,
        control,
        setValue,
        reset,
        watch,
        register,
        formState,
        unregister,
        clearErrors,
    } = useForm<UpdateCustomerInput>({
        defaultValues: {customer: null, accessGroups: []},
    });

    const { accessGroups: accessGroupsWatched } = watch()!;

    const { displayToast } = useToast();

    const connectedUser = useSelector(userSelector);

    const disptxt = "customer Access Groups";

    const isUserInternal = connectedUser?.customerId.includes(1);

    const customers : Customer[] = useSelector(customersArraySelector)!.filter((customer_) => {return customer_.id !== 1 
        && (isUserInternal ? true : !connectedUser.customerId.includes(customer_.id))
        ;});

    const customer = watch("customer");

    const {
        state: { accessGroups },
        addCustomerAccessGroups,
    } = useWidgetState();
    const { dto: accessGroupDto, loaded: accessGroupsLoaded } = accessGroups;
    const user=useSelector(userSelector)

    const currentParentId = customer?.parentId;
    const currentCustomerId = customer?.id;

    const [
        accessGroupsByType,
        accessGroupTypeSelects,
        accessGroupTypeDefaults,
        currentAccessGroupsByType,
    ] = useMemo(() => {
        const r = mapInheritableAccessGroups2(currentParentId, accessGroupDto, currentCustomerId);
        return r
    }, [currentParentId, currentCustomerId, accessGroupDto]);
    useEffect(() => {
        accessGroupTypeSelects.forEach((type, index) => {
            if (accessGroupsByType[type][0].required)
                register(`accessGroups[${index}]`, defaultRules);
            else
                register(`accessGroups[${index}]`);
            setValue(`accessGroups[${index}]`, currentAccessGroupsByType[type]);
        });
        
    }, [accessGroupsByType, accessGroupTypeSelects, register, clearErrors, setValue, currentAccessGroupsByType]);
   
    const getDefaults = useGetLatest([
        accessGroupsByType,
        accessGroupTypeDefaults,
    ] as const);

    const updateCustomerWrapped = useCallback(
        async ({ customer: d, accessGroups: a,}: UpdateCustomerInput) => {
            const [groups, defaults] = getDefaults();
            const aa = [
                ...(a?.flat() || []),
                ...defaults.flatMap((df) => groups[df]),
            ].filter(g => g !== undefined);
            const args = {
                parentId: d!.id,
                accessGroups: aa.map((g) => g.id),
            };
                await ConfigurationsService.updateCustomerAccessGroups(args.parentId, args.accessGroups);
                addCustomerAccessGroups(d!.id, aa);

                if (!saveChanges)
                reset();
                displayToast({
                    message: `${disptxt} ${d?.name} was updated successfully.`,
                    severity: "success",
                    withCloseIcon: true,
                });
        },
        [getDefaults, addCustomerAccessGroups, saveChanges, reset, displayToast]
    );

    const { exec, pending: updatingCustomer, error } = useAsync(
        updateCustomerWrapped,
        {
            immediate: false,
            onError: useErrorHandler({ onValidationError: setError }),
        }
    );

    const subTitle = "Update inheritable access groups of your customers";

    const loading = !accessGroupsLoaded || updatingCustomer;

    return (
        <BaseWidget
            widgetName={widgetName}
            title={`Update ${disptxt}`}
            subTitle={subTitle}
            onSubmit={handleSubmit(exec)}
            error={error}
            loading={loading}
            favouriteWidgets={favouriteWidgets}
            setFavouriteWidgets={setFavouriteWidgets}
        >
            
            <Controller
                name="customer"
                control={control}
                rules={defaultRules}
                render={({ onChange, ...props }) => {
                    return (
                        <AutocompleteV2
                            selectOnTab
                            {...props}
                            disabled={loading}
                            options={customers}
                            noOptionsText="No such customer"
                            getOptionLabel={(r) => `(${r.id}) ${r.name}`}
                            renderInput={(params) => {
                                return (
                                    <TextField
                                        {...params}
                                        margin="dense"
                                        variant="outlined"
                                        fullWidth
                                        label="Customer"
                                        placeholder="Choose a customer"
                                        required
                                        {...getErrorProps(errors, "customer")}
                                    />
                                );
                            }}
                            onChange={(_ev, val) => {
                                clearErrors("accessGroups");
                                unregister(
                                    accessGroupTypeSelects.map(
                                        (_, i) => `accessGroups[${i}]`
                                    )
                                );
                                
                                onChange(val);
                            }}
                        />
                    );
                }}
            />
            
            {accessGroupTypeSelects.map((type, idx) => (
                <AutocompleteV2
                    selectOnTab
                    multiple
                    key={type}
                    options={accessGroupsByType[type]}
                    value={accessGroupsWatched?.[idx] ?? []}
                    disabled={loading}
                    getOptionLabel={(o) => {
                        if (o.name !== undefined && o.name !== o.shortName)
                            return "("+o.shortName+") "+o.name;
                        return o.shortName;
                    }}
                    getOptionSelected={(o1, o2) => o1.id === o2.id}
                    renderInput={(params) => {
                        return (
                            <TextField
                                {...params}
                                margin="dense"
                                variant="outlined"
                                fullWidth
                                label={type}
                                required={accessGroupsByType[type][0].required}
                                {...getErrorProps(
                                    errors,
                                    `accessGroups[${idx}]` as any
                                )}
                            />
                        );
                    }}
                    onChange={(_ev, val) => {
                        setValue(`accessGroups[${idx}]`, val as any, {
                            shouldDirty: formState.isDirty,
                            shouldValidate: formState.isSubmitted,
                        });
                    }}
                />
            ))}
        </BaseWidget>
    );
}
