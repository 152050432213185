import type {
    AccessGroupsDto,
    CustomerAccessGroup,
} from "src/services/UserService";

export default function mapInheritableAccessGroups(
    dealerId: number | undefined,
    accessGroupDto: AccessGroupsDto
) {
    const inheritableAccessGroups =
        accessGroupDto[dealerId as number]?.filter((g) => g.inheritable) ?? [];
    const byType = inheritableAccessGroups.reduce(
        (acc, next) => ({
            ...acc,
            [next.accessGroupType]: [
                ...(acc[next.accessGroupType] || []),
                next,
            ],
        }),
        {} as Record<string, CustomerAccessGroup[]>
    );
    const [selects, defaults] = Object.keys(byType).reduce(
        (acc, next) =>
            ((byType[next].length > 1 && acc[0].push(next)) ||
                acc[1].push(next)) &&
            (acc as any),
        [[], []] as string[][]
    );
    return [byType, selects, defaults] as const;
}

export function mapInheritableAccessGroups2(
    dealerId: number | undefined,
    accessGroupDto: AccessGroupsDto,
    customerId?: number,
) {
    const inheritableAccessGroups =
        accessGroupDto[dealerId as number]?.filter((g) => g.inheritable) ?? [];
    const currentCustomerInhAccessGroups =customerId? accessGroupDto[customerId as number]?.filter((g) => g.inheritable) ?? [] : [];
    currentCustomerInhAccessGroups.forEach((g) => {
        if (inheritableAccessGroups.findIndex((iag) => iag.id === g.id) === -1) {
            inheritableAccessGroups.push(g);
        }
    });
    
    const byType = inheritableAccessGroups.reduce(
        (acc, next) => ({
            ...acc,
            [next.accessGroupType]: [
                ...(acc[next.accessGroupType] || []),
                next,
            ],
        }),
        {} as Record<string, CustomerAccessGroup[]>
    );
    const currentByType = currentCustomerInhAccessGroups.reduce(
        (acc, next) => ({
            ...acc,
            [next.accessGroupType]: [
                ...(acc[next.accessGroupType] || []),
                next,
            ],
        }),
        {} as Record<string, CustomerAccessGroup[]>
    );
    const [selects, defaults] = Object.keys(byType).reduce(
        (acc, next) =>
            ((true && acc[0].push(next)) ||
                acc[1].push(next)) &&
            (acc as any),
        [[], []] as string[][]
    );
    return [byType, selects, defaults, currentByType] as const;
}
